<template>
  <div class="close-header-title no-con">
    <section class="pt_large pb_large shop-inner-section">
      <div class="container">
        <div class="card">
          <div class="container-fliud">
            <div class="wrapper row m-0">
              <div class="preview col-md-12 p-0">
                <div class="row thumbnail-holder m-0">
                  <div class="col-lg-6 p-0">
                    <div class="thumbnail-img">
                      <a v-if="property.photos && property.photos.length > 0" :href="property.photos[0].path"
                        data-fancybox="gallery">
                        <img :src="property.photos[0].path" alt="image" style="width: 100%;" />
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 p-0">
                    <div class="thumbnail-img" v-for="(image, index) in property.photos.slice(1, 3)"
                      :key="'thumb-' + index">
                      <a :href="image.path" data-fancybox="gallery">
                        <img :src="image.path" alt="image" style="width: 100%;" />
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 p-0">
                    <div class="thumbnail-img" v-for="(image, index) in property.photos.slice(3, 5)"
                      :key="'thumb-' + index">
                      <a :href="image.path" data-fancybox="gallery">
                        <img :src="image.path" alt="image" style="width: 100%;" />
                      </a>
                    </div>
                  </div>
                </div>

              </div>
              <div class="tabs-list">
                <ul>
                  <li><a class="active" href="#">Overview</a></li>
                </ul>
              </div>
              <div class="product-detail-sec">
                <div class="row">
                  <div class="details details-section col-md-8">
                    <h1 class="product-title">{{ $t('property_detail.' + property.property_type) }}
                    </h1>
                    <div class="popular-amenities">
                      <!-- <h3>{{ $t('Propertis.popular_amenities') }}</h3> -->
                      <h3>Amenities</h3>
                      <ul>
                        <li>
                          <div class="air-condition">
                            <span><i class="fa fa-bed" aria-hidden="true"></i></span>
                            <span>{{ property.num_bedrooms }} {{ $t('Propertis.Bedrooms') }} </span>
                          </div>
                        </li>
                        <li v-if="property.kitchen_available === 1">
                          <div class="air-condition">
                            <span><i class="fa fa-cutlery" aria-hidden="true"></i></span>
                            <span>{{ $t('Propertis.Kitchen') }}</span>
                          </div>
                        </li>
                        <li v-if="property.tv_available === 1">
                          <div class="air-condition">
                            <span><i class="fa fa-tv" aria-hidden="true"></i></span>
                            <span>{{ $t('Propertis.TV') }}</span>
                          </div>
                        </li>
                        <li v-if="property.wifi_available === 1">
                          <div class="air-condition">
                            <span><i class="fa fa-wifi" aria-hidden="true"></i></span>
                            <span>{{ $t('Propertis.Wifi') }}</span>
                          </div>
                        </li>
                        <li>
                          <div class="air-condition">
                            <span><i class="fa fa-bath" aria-hidden="true"></i></span>
                            <span>{{ property.num_bathrooms }} {{ $t('Propertis.Bathroom') }} </span>
                          </div>
                        </li>
                      </ul>
                      <b-button type="button" class="see-all-btn" v-b-modal.modal-scrollable>
                        <span aria-hidden="true">See all</span>
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      </b-button>
                    </div>
                    <div class="explore-area">
                      <h3>{{ $t('Propertis.explore') }}</h3>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="map-sec" ref="map" style="height: 200px;"></div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="about-property">
                      <h2>About this property</h2>
                      <div class="about-details">
                        <h3>1-{{ property.property_type }}</h3>
                        <p>{{ property.property_description }}</p>
                      </div>
                      <div class="about-details">
                        <h3>Property Owner</h3>
                        <div class="image">
                          <img src="http://bootdey.com/img/Content/avatar/avatar1.png " alt="image"
                            style="border-radius: 20px;">
                        </div>
                        <p>{{ property.user.name }}</p>
                      </div>
                    </div> -->

                  </div>

                  <div class="col-md-4 relative">

                    <div v-if="longdis">
                      <div class="timer"><span>After</span> <span>{{ longdis }}%</span> <span>Discount</span> </div>
                    </div>
                    <div v-else-if="pfDisprice">
                      <div class="timer"><span>Ends In</span> <span>{{ formattedTime }}</span></div>
                    </div>
                    <div class="pricing-detail-box">
                      <div v-if="pfDisprice" class="strikethrough">
                        <h2 v-if="totaldicPrice1 === 0" style="margin-bottom: -15px;">{{ currency }} {{ pfDisprice }}
                        </h2>
                        <h2 v-else style="margin-bottom: -15px;">{{ currency }} {{ totaldicPrice1 }} </h2>
                      </div> <br>
                      <div class="heading" v-if="totalPrice1 === 0">
                        <h2>{{ currency }} {{ pfprice }}</h2>
                        <span>{{ $t('Propertis.avg_per_night') }}</span>

                      </div>
                      <div class="heading" v-else>
                        <h2>{{ currency }} {{ totalPrice1 }}</h2>
                      </div>
                      <div class="date-pricing">
                        <svg
                          class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-icon-small uitk-icon-negative-theme"
                          aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink">
                          <path
                            d="M12 17.042a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0-3.396a.75.75 0 0 0 .75-.75V7.783a.75.75 0 1 0-1.5 0v5.113c0 .415.336.75.75.75z">
                          </path>
                          <path
                            d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18.5c-4.687 0-8.5-3.813-8.5-8.5 0-4.687 3.813-8.5 8.5-8.5 4.687 0 8.5 3.813 8.5 8.5 0 4.687-3.813 8.5-8.5 8.5z">
                          </path>
                        </svg>
                        <span>{{ $t('Propertis.Full_refund') }}</span>
                      </div>
                      <form action="#" class="payment-form">
                        <div class="">
                          <div class="form-field">
                            <div class="date-picker" style="display: none;">
                              <date-range-picker v-model="dateRange" :format="format" :placeholder="placeholder"
                                :date-format="dateFormat" :ranges='false' :locale-data="locale_data" :key="localeKey"
                                :min-date="today" />

                            </div>
                          </div>
                          <!-- <div class="date-picker-container mb-3">
                            <date-picker v-model="dateRange.startDate" valueType="format"
                              placeholder="Check In"></date-picker>
                            <date-picker v-model="dateRange.endDate" valueType="format"
                              placeholder="Check Out"></date-picker>
                          </div> -->
                          <div class="form-field">
                            <div class="date-picker">
                              <date-range-picker v-model="dateRange" :min-date="today" :format="format"
                                :placeholder="placeholder" :date-format="dateFormat" :ranges='false'
                                :locale-data="locale_data" :key="localeKey" />

                            </div>
                          </div>
                          <div class="accordian-guests">
                            <div class="form-field" @click="togglePopup">
                              <p class="form-control" style="padding: 14px; border-radius: 10px;">
                                {{ property.max_guests }} Guests
                              </p>
                            </div>
                            <div class="popup-guest" v-if="isPopupVisible">
                              <div class="guest-content">
                                <div class="guest-col">
                                  <h4 style="margin-right: 10px;">Adults</h4>
                                </div>
                                <div class="guest-col">
                                  <template>
                                    <div class="quantity">
                                      <b-input-group>
                                        <b-input-group-prepend>
                                          <b-btn variant="info" @click="decrement">-</b-btn>
                                        </b-input-group-prepend>

                                        <b-form-input type="number" min="0.00"
                                          v-model="property.max_guests"></b-form-input>

                                        <b-input-group-append>
                                          <b-btn variant="info" @click="increment">+</b-btn>
                                        </b-input-group-append>
                                      </b-input-group>
                                    </div>
                                  </template>
                                </div>
                              </div>
                              <div class="guest-content" v-if="property.children !== 'Not Allowd'">
                                <div class="guest-col">
                                  <h4 style="margin-right: 10px;">Childs</h4>
                                </div>
                                <div class="guest-col">
                                  <template>
                                    <div class="quantity">
                                      <b-input-group>
                                        <b-input-group-prepend>
                                          <b-btn variant="info" @click="childDecrement">-</b-btn>
                                        </b-input-group-prepend>

                                        <b-form-input type="number" min="0.00" :value="childQuantity"></b-form-input>

                                        <b-input-group-append>
                                          <b-btn variant="info" @click="childIncrement">+</b-btn>
                                        </b-input-group-append>
                                      </b-input-group>
                                    </div>
                                  </template>
                                </div>
                              </div>
                              <div class="guest-content" v-if="property.Pets !== 'Not Allowd'">
                                <div class="guest-col">
                                  <h4 style="margin-right: 10px;">Pets</h4>
                                </div>
                                <div class="guest-col">
                                  <template>
                                    <div class="quantity">
                                      <b-input-group>
                                        <b-input-group-prepend>
                                          <b-btn variant="info" @click="petDecrement">-</b-btn>
                                        </b-input-group-prepend>

                                        <b-form-input type="number" min="0.00" :value="petQuantity"></b-form-input>

                                        <b-input-group-append>
                                          <b-btn variant="info" @click="petIncrement">+</b-btn>
                                        </b-input-group-append>
                                      </b-input-group>
                                    </div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="pricing-anchor">
                          <a href="#">Price details</a>
                        </div> -->
                        <div class="form-btn">
                          <!-- <button type="button" class="availbility-btn">Check availability</button> -->
                          <button class="availbility-btn" type="button" @click="redirectToPayment">{{
                            $t('property_detail.book_button') }}</button>
                        </div>
                      </form>
                      <div>
                      </div>
                      <div class="share-buttons">
                        <ShareNetwork class="share-icons" network="facebook" :url="currentPageUrl"
                          :title="property.property_type" :description="property.property_description"
                          :quote="`Check out this amazing property: ${property.property_type} with ${property.num_bedrooms} bedrooms and ${property.num_bathrooms} bathrooms.`"
                          :hashtags="`property,${property.property_type}`">
                          <i class="fa fa-facebook"></i>
                        </ShareNetwork>
                        <ShareNetwork class="share-icons" network="twitter" :url="currentPageUrl"
                          :title="property.property_type" :description="property.property_description"
                          :quote="`Check out this amazing property: ${property.property_type} with ${property.num_bedrooms} bedrooms and ${property.num_bathrooms} bathrooms.`"
                          :hashtags="`property,${property.property_type}`">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                              d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                          </svg>
                        </ShareNetwork>
                        <ShareNetwork class="share-icons" network="linkedin" :url="currentPageUrl"
                          :title="property.property_type" :description="property.property_description"
                          :quote="`Check out this amazing property: ${property.property_type} with ${property.num_bedrooms} bedrooms and ${property.num_bathrooms} bathrooms.`"
                          :hashtags="`property,${property.property_type}`">
                          <i class="fa fa-linkedin"></i>
                        </ShareNetwork>
                      </div>
                    </div>
                    <div v-if="discount" class="long_stay_dis">
                      <h4>Discount on Long Stays</h4>
                      <div class="long_dis">
                        <h5>For One Week</h5>
                        <p>If You Book For One week You will get {{ discount.oneweekdisc }}% Discount</p>
                      </div>
                      <div class="long_dis">
                        <h5>For Two Week</h5>
                        <p>If You Book For Two week You will get {{ discount.twoweekdisc }}% Discount</p>
                      </div>
                      <div class="long_dis">
                        <h5>For Three Week</h5>
                        <p>If You Book For Three week You will get {{ discount.threeweekdisc }}% Discount</p>
                      </div>
                      <div class="long_dis">
                        <h5>For One Month</h5>
                        <p>If You Book For One Month You will get {{ discount.onemonthdis }}% Discount</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="two-column mb-5" style="margin-bottom: 10px !important;">
                  <div class="row">
                    <div class="col-lg-5">
                      <div class="heading">
                        <h2>{{ $t('Propertis.about_this') }}</h2>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="two-column mb-5">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div v-if="property.kitchen_available === 1" class="col-md-12">
                          <div class="about-details row mb-5">
                            <div class="col-lg-4">
                              <h2>{{ $t('Propertis.Property_Owner') }}</h2>
                            </div>
                            <p>{{ property.user.name }}</p>
                          </div>

                        </div>
                      </div>
                      <div class="row">

                        <div class="col-md-12">
                          <div class="about-details row">
                            <div class="col-lg-4">
                              <h2>Description </h2>
                            </div>
                            <div class="col-lg-8">
                              <p>Test description</p>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-12">
                            <div class="about-details row">
                              <div class="col-lg-4">
                                  <h3>Property Type </h3>
                              </div>
                              <div class="col-lg-8">
                                  <p>{{ $t('property_detail.' + property.property_type) }}</p>
                              </div>
                            </div>
                          </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="two-column mb-5">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="heading">
                        <h2>{{ $t('Propertis.Amenities') }}</h2>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <div class="popular-amenities">
                        <ul>
                          <li>
                            <div class="air-condition">
                              <span><i class="fa fa-bed" aria-hidden="true"></i></span>
                              <span>{{ property.num_bedrooms }} {{ $t('Propertis.Bedrooms') }}
                              </span>
                            </div>
                          </li>
                          <li v-if="property.kitchen_available === 1">
                            <div class="air-condition">
                              <span><i class="fa fa-cutlery" aria-hidden="true"></i></span>
                              <span>{{ $t('Propertis.Kitchen') }}</span>
                            </div>
                          </li>
                          <li v-if="property.tv_available === 1">
                            <div class="air-condition">
                              <span><i class="fa fa-tv" aria-hidden="true"></i></span>
                              <span>{{ $t('Propertis.TV') }}</span>
                            </div>
                          </li>
                          <li v-if="property.wifi_available === 1">
                            <div class="air-condition">
                              <span><i class="fa fa-wifi" aria-hidden="true"></i></span>
                              <span>{{ $t('Propertis.WIFI') }}</span>
                            </div>
                          </li>
                          <li>
                            <div class="air-condition">
                              <span><i class="fa fa-bath" aria-hidden="true"></i></span>
                              <span>{{ property.num_bathrooms }} {{ $t('Propertis.Bathroom') }} </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="two-column mb-5">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="heading">
                        <h2>{{ $t('property_detail.Reviews') }}
                        </h2>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <div class="popular-amenities">
                        <ul>
                          <li>
                            <div class="stars">
                              <span class="fa fa-star" :class="{ 'checked': rating >= 1 }"></span>
                              <span class="fa fa-star" :class="{ 'checked': rating >= 2 }"></span>
                              <span class="fa fa-star" :class="{ 'checked': rating >= 3 }"></span>
                              <span class="fa fa-star" :class="{ 'checked': rating >= 4 }"></span>
                              <span class="fa fa-star" :class="{ 'checked': rating >= 5 }"></span>
                            </div>
                          </li>
                          <li>
                            <div class="air-condition">
                              <a href="#" @click.prevent="redirectToRating"><span class="review-no">{{ reviews }} {{
                                $t('property_detail.Reviews') }} </span></a>
                              <!-- <p>Be the first to leave a review for this property after you stay.</p> -->
                            </div>
                          </li>
                          <li>
                            <div class="air-condition">
                              <div>
                                <span class="rating-counter" v-if="rating > 0">{{ formattedRating }}</span>
                                <p v-else>{{ $t('Propertis.be_first') }}
                                </p>
                              </div>

                              <!-- Display the review feedback message -->
                              <div class="review-feedback ml-1" v-if="rating > 0">
                                <span>({{ getReviewFeedback(rating) }})</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="two-column mb-5">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="heading">
                        <h2>{{ $t('Propertis.House_Rules') }}</h2>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <div class="popular-amenities">
                        <ul>
                          <li>
                            <div style="padding-bottom: 20px;">
                              <div class="air-condition">
                                <span>{{ $t('Propertis.Check_in_after') }}: {{ formatTime(property.checkin_after) }}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div style="padding-bottom: 20px;">
                              <div class="air-condition">
                                <span>{{ $t('Propertis.Check_out_before') }}: {{ formatTime(property.checkout_before)
                                  }}</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div style="padding-bottom: 20px;">
                              <div class="air-condition" style="padding-bottom: 5px;">
                                <i style=" margin-right: 10px;  font-size: 25px;" class="fa fa-child"
                                  aria-hidden="true"></i>
                                <span style="font-size: large;"><b>{{ $t('Propertis.Children') }}</b> </span>
                              </div>
                              <div class="air-condition">
                                <span>{{ $t('Propertis.Children') }}: {{ property.children }} </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div style="padding-bottom: 20px;">
                              <div class="air-condition" style="padding-bottom: 5px;">
                                <img style=" margin-right: 10px; max-width: 6% !important;"
                                  src="../public/assets/images/Events.svg" alt="Events">
                                <span style="font-size: large;"><b>{{ $t('Propertis.Events') }}</b> </span>
                              </div>
                              <div class="air-condition">
                                <span>{{ $t('Propertis.Events') }}: {{ property.Events }} </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div style="padding-bottom: 20px;">
                              <div class="air-condition" style="padding-bottom: 5px;">
                                <!-- <img style=" margin-right: 10px; max-width: 6% !important;"
                                  src="../public/assets/images/pets.svg" alt="Pets"> -->
                                <i style=" margin-right: 10px;  font-size: 25px;" class="fa fa-paw"
                                  aria-hidden="true"></i>
                                <span style="font-size: large;"><b>{{ $t('Propertis.Pets') }}</b> </span>
                              </div>
                              <div class="air-condition">
                                <span>{{ $t('Propertis.Pets') }}: {{ property.Pets }} </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div style="padding-bottom: 20px;">
                              <div class="air-condition" style="padding-bottom: 5px;">
                                <img style=" margin-right: 10px; max-width: 6% !important;"
                                  src="../public/assets/images/Smoking.svg" alt="Smoking">
                                <span style="font-size: large;"><b>{{ $t('Propertis.Smoking') }}</b> </span>
                              </div>
                              <div class="air-condition">
                                <span>{{ $t('Propertis.Smoking') }}: {{ property.Smoking }} </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="two-column mb-5">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="heading">
                        <h2>{{ $t('Propertis.Damage_and_incidentals') }}</h2>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <div class="paragrapgh">
                        <p>{{ $t('Propertis.demage_responsoble') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="two-column mb-5">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="heading">
                        <h2>{{ $t('Propertis.Cancellation') }}</h2>
                      </div>
                    </div>
                    <div class="col-lg-8 ">
                      <div class="paragrapgh">
                        <div class="row cancelbar">
                          <div class="col-lg-12 cancelbar">
                            <div class="row" style="    position: relative;margin-bottom: -12px;">
                              <div class="col-lg-4" v-if="fullrefund">
                                <p style="margin-left: 62%;"> {{ $t('Propertis.Full_refund') }} </p>
                              </div>

                              <div class="col-lg-4" v-if="parrefund">
                                <p :style="partialRefundStyle">{{ $t('Propertis.Partial_refund') }} </p>
                                <!-- <p style="margin-left: 25%;">Partial refund
                                </p> -->
                              </div>
                              <div class="col-lg-4">
                                <p :style="noRefundStyle">{{ $t('Propertis.No_refund') }}</p>
                              </div>
                            </div>
                            <div class="root">
                              <ul class="progressbar">
                                <li class="active"> <i class="fa fa-circle" aria-hidden="true"></i>Today</li>
                                <li v-if="fullrefund"><i class="fa fa-circle" aria-hidden="true"></i>{{ crefund }} </li>
                                <li v-if="parrefund"> <i class="fa fa-times" aria-hidden="true"></i>{{ prefund }} </li>
                                <li> <i class="fa fa-times" aria-hidden="true"></i>Check-in </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="fullrefund">
                          <div class="col-lg-2">
                            <p style="margin-bottom: 0px;"><b style="font-weight: 500;">{{ $t('Propertis.Before') }}</b>
                            </p>
                            <p><b
                                style="font-size: 1rem; line-height: 1.25rem; min-inline-size: calc(4px + 1.25rem);">{{
                                  crefund }}</b></p>
                          </div>
                          <div class="col-lg-8">
                            <p><b
                                style="font-size: 1rem; line-height: 1.25rem; min-inline-size: calc(4px + 1.25rem);">{{
                                  $t('Propertis.Full_refund') }}</b>
                            </p>
                            <p style="font-weight: 400;     font-size: .75rem; line-height: 1rem;">
                              {{ $t('Propertis.f_Ref') }} </p>
                            <!-- <p style="font-weight: 400;     font-size: .75rem; line-height: 1rem;">Cancel your
                              reservation before {{ crefund }} at 11:59 PM, and you'll get a full refund. Times are
                              based on
                              the property's local time.</p> -->
                          </div>
                        </div>
                        <hr>
                        <div class="row" v-if="parrefund">
                          <div class="col-lg-2">
                            <p style="margin-bottom: 0px;"><b style="font-weight: 500;">{{ $t('Propertis.Before') }}</b>
                            </p>
                            <p><b
                                style="font-size: 1rem; line-height: 1.25rem; min-inline-size: calc(4px + 1.25rem);">{{
                                  prefund }} </b></p>
                          </div>
                          <div class="col-lg-8">
                            <p><b
                                style="font-size: 1rem; line-height: 1.25rem; min-inline-size: calc(4px + 1.25rem);">{{
                                  $t('Propertis.Partial_refund') }}</b>
                            </p>
                            <p style="font-weight: 400;     font-size: .75rem; line-height: 1rem;">
                              {{ $t('Propertis.p_ref') }}</p>
                            <!-- <p style="font-weight: 400;     font-size: .75rem; line-height: 1rem;">If you cancel your
                              reservation before {{ prefund }} at 11:59 PM you'll get a refund of 50% of the amount paid
                              (minus
                              the service fee). Times are based on the property's local time.</p> -->
                          </div>
                        </div>
                        <hr>
                        <div class="row">
                          <div class="col-lg-2">
                            <p v-if="nrefund">
                              <b style="font-weight: 500;">Until</b>
                            </p>
                            <p v-else style="margin-bottom: 0;">
                              <b style="font-weight: 500;">{{ $t('Propertis.After') }}</b>
                            </p>
                            <p v-if="nrefund">
                              <b style="font-weight: 500;">{{ rfdate }}</b>
                            </p>
                            <p v-else style="margin-bottom: 0;">
                              <b style="font-size: 1rem; line-height: 1.25rem; min-inline-size: calc(4px + 1.25rem);">{{
                                this.prefund }} </b>
                            </p>
                          </div>
                          <div class="col-lg-8">
                            <p><b
                                style="font-size: 1rem; line-height: 1.25rem; min-inline-size: calc(4px + 1.25rem);">{{
                                  $t('Propertis.No_refund') }}</b>
                            </p>


                            <p v-if="nrefund" style="font-weight: 400; font-size: .75rem; line-height: 1rem;">If you
                              change or cancel your booking you will not get a
                              refund or credit to use for a future stay
                            </p>
                            <p v-else style="font-weight: 400; font-size: .75rem; line-height: 1rem;">
                              {{ $t('Propertis.n_ref') }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="two-column mb-5">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="heading">
                        <h2>{{ $t('Propertis.Important_information') }}</h2>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <div class="paragrapgh">
                        <h3>{{ $t('Propertis.You_need_to_know') }}</h3>
                        <p>{{ $t('Propertis.extes_person') }}</p>
                        <p>{{ $t('Propertis.id_card') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="two-column mb-5">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="heading">
                        <!-- <h2>{{ $t('Propertis.send_messager') }}</h2> -->
                        <h2>Send Message To Property Owner</h2>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <form @submit.prevent="handleSubmit" class="send-message">
                        <div class="form-group">
                          <label for="email">{{ $t('Propertis.Email_address') }}</label>
                          <input type="email" class="form-control" id="email" v-model="form.email"
                            :class="{ 'is-invalid': formErrors.email }" :placeholder="$t('Propertis.enter_email')">
                          <div class="invalid-feedback">{{ formErrors.email }}</div>
                        </div>
                        <div class="form-group">
                          <label for="message">{{ $t('Propertis.Message') }}</label>
                          <textarea class="form-control" id="message" v-model="form.message"
                            :class="{ 'is-invalid': formErrors.message }" rows="5"
                            :placeholder="$t('Propertis.enter_message')"></textarea>
                          <div class="invalid-feedback">{{ formErrors.message }}</div>
                        </div>
                        <!-- <div class="form-group">
                          <div class="paragrapgh">
                            <h3>{{ $t('Send Message To Property Owner') }}</h3>
                          </div>
                        </div> -->
                        <button type="submit" class="btn btn-primary">Send</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <b-modal id="modal-scrollable" scrollable hide-footer title="Property Amenities">
      <div class="popular-amenities mb-2">
        <h3>Amenities</h3>
        <ul>
          <li v-if="property.kitchen_available === 1">
            <div class="air-condition">
              <span><i class="fa fa-cutlery" aria-hidden="true"></i></span>
              <span>{{ $t('Propertis.Kitchen') }}</span>
            </div>
          </li>
          <li v-if="property.tv_available === 1">
            <div class="air-condition">
              <span><i class="fa fa-tv" aria-hidden="true"></i></span>
              <span>{{ $t('Propertis.TV') }}</span>
            </div>
          </li>
          <li v-if="property.wifi_available === 1">
            <div class="air-condition">
              <span><i class="fa fa-wifi" aria-hidden="true"></i></span>
              <span>Wifi</span>
            </div>
          </li>
        </ul>
      </div>
      <div>
        <div class="popular-amenities mb-2" v-if="property.wifi_available === 1">
          <h3>Internet</h3>
          <ul>
            <li>Available in the apartment: WiFi</li>
          </ul>
        </div>
      </div>
      <div class="popular-amenities mb-2">
        <h3>Bedrooms</h3>
        <ul>
          <li>
            <div class="air-condition">
              <span><i class="fa fa-bed" aria-hidden="true"></i></span>
              <span>{{ property.num_bedrooms }} {{ $t('Propertis.Bedrooms') }}
              </span>
            </div>
          </li>
          <li v-if="property.king_size_bed != null"> {{ property.king_size_bed }} King Bed</li>
          <li v-if="property.queen_size_bed != null"> {{ property.queen_size_bed }} Queen Bed</li>
          <li v-if="property.sofa_bed != null"> {{ property.sofa_bed }} Sofa Bed</li>
          <!-- <li>Bedsheets provided</li> -->
        </ul>
      </div>
      <div class="popular-amenities mb-2">
        <h3>Bathroom</h3>
        <ul>
          <li>
            <div class="air-condition">
              <span><i class="fa fa-bath" aria-hidden="true"></i></span>
              <span>{{ property.num_bathrooms }} bathroom </span>
            </div>
          </li>
          <!-- <li>{{ property.num_bathrooms }} bathroom</li> -->
        </ul>
      </div>
      <div class="popular-amenities mb-2">
        <ul>
          <li>
            <div class="air-condition">
              <div class="content-list mb-3">
                <ul>
                  <h3>{{ $t('Propertis.Property_Owner') }}</h3>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div class="air-condition">
              <div class="col-lg-8">
                <div class="image">
                  <img src="http://bootdey.com/img/Content/avatar/avatar1.png " alt="image"
                    style="border-radius: 20px;height: 25px; width: 25px;">
                </div>
                <p>{{ property.user.name }}</p>
              </div>
            </div>
          </li>
        </ul>
        <ul>
          <li>
            <div class="air-condition">
              <div class="content-list mb-3">
                <ul>
                  <h3>Description</h3>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div class="air-condition">
              <div class="col-lg-8">
                <p>Test description</p>
              </div>
            </div>
          </li>
        </ul>
      </div>

    </b-modal>
  </div>
</template>
<script>
import API from '@/api'
import DateRangePicker from 'vue2-daterange-picker';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Fancybox } from '@fancyapps/ui';
import moment from 'moment';
import Swal from 'sweetalert2';
import Vue from 'vue';
import axios from 'axios';
import { VStepper } from 'vue-stepper-component'
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);
import DatePicker from 'vue2-datepicker';
import { Loader } from '@googlemaps/js-api-loader';
export default {
  name: 'propertyDetail',
  components: {
    DateRangePicker,
    // DatePicker,
  },
  data() {
    const date = new Date();
    date.setDate(date.getDate() + 20);
    const rfdate = new Date();
    rfdate.setDate(rfdate.getDate() + 4);
    const options = { month: 'short', day: '2-digit' };
    const formattedDate = rfdate.toLocaleDateString('en-US', options);
    return {
      targetDate: 0,
      targetTime: 0,
      remainingTime: 0,
      timer: null,
      orginalguest: '',
      time1: null,
      time2: null,
      time3: null,
      ftoday: date,
      isPopupVisible: false,
      quantity: 1,
      childQuantity: 0,
      petQuantity: 0,
      rfdate: formattedDate,
      today: new Date(),
      totalPrice1: 0,
      totaldicPrice1: 0,
      totalPrice2: 0,
      pfprice: null,
      pffprice: null,
      pfDisprice: null,
      longdis: null,
      pffDisprice: null,
      currency: null,
      localeKey: 0,
      property: {},
      discount: {},
      ratingVal: {},
      baseUrl: '',
      activeIndex: 0,
      booked: false,
      rating: 0,
      reviews: 0,
      dateRange: {
        startDate: new Date(),
        endDate: new Date(new Date().setDate(new Date().getDate() + 1))
      },
      minDaysToStay: '',
      placeholder: 'Select Date Range',
      format: 'YYYY-MM-DD',
      form: {
        email: '',
        message: ''
      },
      formErrors: {
        email: '',
        message: ''
      },
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      totalPrice: 0,
      disabledDates: [],
      map: null,
      address: '',
      currentLanguage: '',
      fullrefund: false,
      parrefund: false,
      nrefund: false,

    }
  },
  computed: {
    formattedTime() {
      const milliseconds = this.remainingTime;
      const totalSeconds = Math.floor(milliseconds / 1000);
      const days = Math.floor(totalSeconds / (3600 * 24));
      const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    },
    formattedRating() {
      return this.rating.toFixed(2);
    },
    partialRefundStyle() {
      if (this.fullrefund) {
        return { 'margin-left': '25%' };
      } else {
        return { 'margin-left': '59%' };
      }
    },
    noRefundStyle() {
      if (this.fullrefund) {
        return { 'margin-left': '0%' };
      } else if (this.parrefund) {
        return { 'margin-left': '30%' };
      } else {
        return { 'margin-left': '60%' };
      }
    },
    prefund() {
      const futureDate = new Date(this.ftoday);
      futureDate.setDate(futureDate.getDate() - 8);
      const options = { month: 'short', day: '2-digit' };
      return futureDate.toLocaleDateString('en-US', options);
    },
    crefund() {
      const futureDate = new Date(this.ftoday);
      futureDate.setDate(futureDate.getDate() - 15);
      const options = { month: 'short', day: '2-digit' };
      return futureDate.toLocaleDateString('en-US', options);
    },
    pprice() {
      return this.property ? this.property : null;
    },
    currentPageUrl() {
      return window.location.href;
    },
  },
  watch: {
    '$i18n.locale': {
      handler(newVal, oldVal) {
        this.localeKey++;
        this.updateTranslations();
      },
      immediate: true
    },
    dateRange: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal === null) {
          return;
        }
        const startMoment = moment(newVal.startDate);
        const endMoment = moment(newVal.endDate);
        const minDaystoStay = this.property.min_guests;
        const differenceInDays = endMoment.diff(startMoment, 'days');
        if (differenceInDays < minDaystoStay) {
          Swal.fire({
            type: 'error',
            title: this.$t('Error'),
            text: this.$t('Minimum stay for this property is ' + minDaystoStay +' days.'),
            confirmButtonText: this.$t('sweetalert.OK'),
            icon: 'error'
          });

          // Reset the date range
          // this.dateRange.startDate = null;
          // this.dateRange.endDate = null;
          return;
        }
        let totalPrice = this.property.price * differenceInDays;
        let totalPrice1 = this.pfprice * differenceInDays;
        let totaldicPrice1 = this.pfDisprice * differenceInDays;
        let formattedTotalPrice1 = totalPrice1.toFixed(0);
        let formattedTotaldicPrice1 = totaldicPrice1.toFixed(0);
        let formattedTotalPrice = totalPrice.toFixed(0);
        this.totalPrice = formattedTotalPrice;
        this.totalPrice1 = formattedTotalPrice1;
        this.totaldicPrice1 = formattedTotaldicPrice1;
        this.totalPrice2 = formattedTotalPrice1;
        if (differenceInDays >= 7 && differenceInDays < 14) {
          this.longdis = this.discount.oneweekdisc;
          const discountPercentage = this.discount.oneweekdisc;
          const orignalprice = this.pfprice
          const dispersentage = (this.pfprice * discountPercentage) / 100;
          const dicsorignalprice = this.pfprice - dispersentage;
          this.pfprice = dicsorignalprice.toFixed(0);
          const discountAmount = (formattedTotalPrice1 * discountPercentage) / 100;
          this.totaldicPrice1 = formattedTotalPrice1;
          const discount = formattedTotalPrice1 - discountAmount;
          this.totalPrice1 = discount.toFixed(0)
          this.pfDisprice = 1;
        } else if (differenceInDays >= 14 && differenceInDays < 21) {
          this.longdis = this.discount.twoweekdisc;
          const discountPercentage = this.discount.twoweekdisc;
          const orignalprice = this.pfprice
          const dispersentage = (this.pfprice * discountPercentage) / 100;
          const dicsorignalprice = this.pfprice - dispersentage;
          this.pfprice = dicsorignalprice.toFixed(0);
          const discountAmount = (formattedTotalPrice1 * discountPercentage) / 100;
          this.totaldicPrice1 = formattedTotalPrice1;
          const discount = formattedTotalPrice1 - discountAmount;
          this.totalPrice1 = discount.toFixed(0)
          this.pfDisprice = 1;
        } else if (differenceInDays >= 21 && differenceInDays < 28) {
          this.longdis = this.discount.threeweekdisc;
          const discountPercentage = this.discount.threeweekdisc;
          const orignalprice = this.pfprice
          const dispersentage = (this.pfprice * discountPercentage) / 100;
          const dicsorignalprice = this.pfprice - dispersentage;
          this.pfprice = dicsorignalprice.toFixed(0);
          const discountAmount = (formattedTotalPrice1 * discountPercentage) / 100;
          this.totaldicPrice1 = formattedTotalPrice1;
          const discount = formattedTotalPrice1 - discountAmount;
          this.totalPrice1 = discount.toFixed(0);
          this.pfDisprice = 1;
        } else if (differenceInDays >= 28) {
          this.longdis = this.discount.onemonthdis;
          const discountPercentage = this.discount.onemonthdis;
          const orignalprice = this.pfprice
          const dispersentage = (this.pfprice * discountPercentage) / 100;
          const dicsorignalprice = this.pfprice - dispersentage;
          this.pfprice = dicsorignalprice.toFixed(0);
          const discountAmount = (formattedTotalPrice1 * discountPercentage) / 100;
          this.totaldicPrice1 = formattedTotalPrice1;
          const discount = formattedTotalPrice1 - discountAmount;
          this.totalPrice1 = discount.toFixed(0);
          this.pfDisprice = 1;
        }
        const today = moment();
        const daysFromToday = startMoment.diff(today, 'days');
        if (daysFromToday > 15) {
          this.ftoday = startMoment;
          this.fullrefund = true;
          this.parrefund = true;
          this.nrefund = false;
        } else if (daysFromToday <= 15 && daysFromToday > 10) {
          this.ftoday = startMoment;
          this.fullrefund = false;
          this.parrefund = true;
          this.nrefund = false;
        } else if (daysFromToday <= 10) {
          this.ftoday = startMoment;
          this.fullrefund = false;
          this.parrefund = false;
          this.nrefund = true;
        }
        if (startMoment.isBefore(moment(), 'day')) {
          Swal.fire({
            type: 'error',
            title: this.$t('sweetalert.Invalid_Date'),
            text: this.$t('sweetalert.f_dates'),
            confirmButtonText: this.$t('sweetalert.OK'),
            icon: 'error'
          });
          this.dateRange.startDate = moment().toDate();
          return;
        }

        if (this.overlapDetected) {
          this.overlapDetected = false;
          return;
        }

        const overlaps = this.checkDateOverlap(startMoment, endMoment);
        if (overlaps) {
          this.overlapDetected = true;

          this.dateRange = {};

          // Show alert
          Swal.fire({
            type: 'error',
            title: this.$t('sweetalert.Oops'),
            text: this.$t('sweetalert.overlap'),
            confirmButtonText: this.$t('sweetalert.OK'),
            icon: 'warning',
          }).then((result) => {
            if (result.isConfirmed) {
              // Refresh the page
              location.reload();
            }
          });
        }
      }
    }
  },
  mounted() {
    const propertyId = sessionStorage.getItem('propertyId');
    this.getProperty(propertyId);
    this.getRatingAndReviews(propertyId);
    this.getBookingsByPropertyID(propertyId);
    Vue.prototype.$EventBus.$on('changeLanguage', this.onLanguageChanged);
    Fancybox.bind("[data-fancybox]", {
      loop: true,
      buttons: ["zoom", "slideShow", "thumbs", "close"],
      // Other options...
    });

  },
  beforeDestroy() {
    Vue.prototype.$EventBus.$off('changeLanguage', this.onLanguageChanged);
    clearInterval(this.timer);
  },
  methods: {
    updateCountdown() {
      const now = new Date().getTime();
      this.remainingTime = this.targetTime - now;
      if (this.remainingTime <= 0) {
        clearInterval(this.timer);
        this.remainingTime = 0; // Ensure it doesn't go negative
      }
    },
    togglePopup() {
      this.isPopupVisible = !this.isPopupVisible;
    },
    increment() {
      this.property.max_guests++;
      if (this.totalPrice1 != 0) {
        const result = this.totalPrice2 / this.orginalguest;
        const fresult = result * this.property.max_guests
        this.totalPrice1 = fresult;
      } else {
        const result = this.pffprice / this.orginalguest;
        const fresult = result * this.property.max_guests
        this.pfprice = fresult;
      }
      // this.property.max_guests++;
    },
    decrement() {
      if (this.property.max_guests > 0) {
        this.property.max_guests--;
        if (this.totalPrice1 != 0) {
          const result = this.totalPrice2 / this.orginalguest;
          const fresult = result * this.property.max_guests
          this.totalPrice1 = fresult;
        } else {
          const result = this.pffprice / this.orginalguest;
          const fresult = result * this.property.max_guests
          this.pfprice = fresult;
        }
      }
    },
    childIncrement() {
      this.childQuantity++;
    },
    childDecrement() {
      if (this.childQuantity > 0) {
        this.childQuantity--;
      }
    },
    petIncrement() {
      this.petQuantity++;
    },
    petDecrement() {
      if (this.petQuantity > 0) {
        this.petQuantity--;
      }
    },
    formatTime(time) {
      const [hours, minutes] = time.split(':').map(Number);

      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(0);
      date.setMilliseconds(0);
      const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
      const formattedHours = date.getHours() % 12 || 12;
      const formattedMinutes = date.getMinutes().toString().padStart(2, '0');

      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    },
    fetchCurrency() {
      let cur = localStorage.getItem('currency')
      if (cur) {
        this.currency = cur
        setTimeout(() => {
          this.covertcurency(this.pprice.price, this.pprice.currency, this.currency, this.pprice.discountedPrice);
        }, 5000);
      } else {
        axios.get('https://ipapi.co/currency/')
          .then(response => {
            this.currency = response.data;
            setTimeout(() => {
              this.covertcurency(this.pprice.price, this.pprice.currency, this.currency, this.pprice.discountedPrice);
            }, 2000);
          })
          .catch(error => {
            this.currency = 'USD'
            setTimeout(() => {
              this.covertcurency(this.pprice.price, this.pprice.currency, 'USD', this.pprice.discountedPrice);
            }, 2000);
            // console.error('Error fetching currency:', error);
          });
      }
    },
    covertcurency(price, currency, ipCurrency, dCurrency) { 
      fetch('https://api.exchangerate-api.com/v4/latest/' + currency)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (dCurrency) {
            const iprate = data.rates[ipCurrency];
            let converteddPrice = iprate * dCurrency;
            let formatteddPrice = converteddPrice.toFixed(0);
            this.pfDisprice = formatteddPrice;
            this.pffDisprice = formatteddPrice;
          }
          const iprate = data.rates[ipCurrency];
          let convertedPrice = iprate * price;
          let formattedPrice = convertedPrice.toFixed(0);
          this.pfprice = formattedPrice;
          this.pffprice = formattedPrice;

        })
        .catch(error => {
          console.error('Error fetching currency:', error);
        });
    },
    changeLanguage(language) {
      Vue.prototype.$EventBus.$emit('changeLanguage', language);
      this.selectedLanguage = language;
    },
    getReviewFeedback(averageRating) {
      if (averageRating >= 4.5) {
        return "Outstanding";
      } else if (averageRating >= 3.5) {
        return "Good";
      } else if (averageRating >= 2.5) {
        return "Average";
      } else if (averageRating >= 1.5) {
        return "Below Average";
      } else {
        return "Poor";
      }
    },
    getImagePath() {
      if (this.property.photos && this.property.photos.length > 0) {
        return this.property.photos[0].path;
      }
      return '';
    },
    validateForm() {
      this.formErrors = {
        email: '',
        message: ''
      };
      let isValid = true;

      if (!this.form.email) {
        this.formErrors.email = 'Email is required.';
        isValid = false;
      } else if (!this.validEmail(this.form.email)) {
        this.formErrors.email = 'Please enter a valid email address.';
        isValid = false;
      }

      if (!this.form.message) {
        this.formErrors.message = 'Message is required.';
        isValid = false;
      }

      return isValid;
    },
    validEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    handleSubmit() {
      if (this.validateForm()) {
        // Here you would normally send the data to your server
        API.submitContactForm(
          this.form,
          data => {
            if (data.status == 200) {
              this.$swal({
                type: 'success',
                title: 'Success',
                text: data.message,
              })
            } else {
              this.$swal({
                type: 'error',
                title: 'Oops...',
                text: data.message,
              })
            }
            this.isDisabled = false;
          },
          err => {
            this.isDisabled = false;
            this.$swal({
              type: 'error',
              title: 'Oops...',
              text: err.message,
            })
          }
        )
      }
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    updateTranslations() {
      this.locale_data = {
        applyLabel: this.$t('pagination.apply'),
        cancelLabel: this.$t('pagination.cancel')
      };
    },
    onLanguageChanged(language) {
      if (language == 'en') {
        this.currentLanguage = '';
      } else {
        this.currentLanguage = '_' + language;
      }

    },
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = this.disabledDates.some(disabledDate => {
          return date.setHours(0, 0, 0, 0) === disabledDate.setHours(0, 0, 0, 0);
        });
      }
      return classes;
    },
    checkDateOverlap(newStartDate, newEndDate) {
      for (const booking of this.disabledDates) {
        const existingStartDate = moment(booking);
        if (
          (newStartDate.isBetween(existingStartDate, existingStartDate, undefined, '[]')) ||
          (newEndDate.isBetween(existingStartDate, existingStartDate, undefined, '[]')) ||
          (existingStartDate.isBetween(newStartDate, newEndDate, undefined, '[]')) ||
          (existingStartDate.isBetween(newStartDate, newEndDate, undefined, '[]'))
        ) {
          return true;
        }
      }
      return false;
    },
    getBookingsByPropertyID(propertyId) {
      API.getBookingsByPropertyID(propertyId, res => {
        const bookings = res.data.data;
        bookings.forEach(booking => {
          const startDate = new Date(booking.start_date);
          const endDate = new Date(booking.end_date);
          const datesInRange = this.getDatesInRange(startDate, endDate);
          this.disabledDates = this.disabledDates.concat(datesInRange);
        });
      }, err => {
        console.log(err);
      });
    },
    getDatesInRange(startDate, endDate) {
      const datesInRange = [];
      let currentDate = startDate;
      while (currentDate <= endDate) {
        datesInRange.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return datesInRange;
    },
    getProperty(propertyId) {
      const originalUrl = this.$route.fullPath;
      API.getProperty(propertyId, res => { 
        this.property = res.data.data;
        this.discount = res.data.discounts;
        this.address = this.property.city + ' ' + this.property.state + ' ' + this.property.country;
        this.orginalguest = this.property.max_guests;
        this.targetDate = this.property.discountedate
        const loader = new Loader({
          apiKey: process.env.VUE_APP_GOOLE_PLACES_API_KEY,
          version: 'weekly',
          libraries: ['places']
        });
        loader.load().then(() => {
          this.map = new window.google.maps.Map(this.$refs.map, {
            center: { lat: 0, lng: 0 },
            zoom: 8
          });
          const geocoder = new window.google.maps.Geocoder();
          const address = this.address;
          geocoder.geocode({ 'address': address }, (results, status) => {
            if (status === 'OK') {
              this.map.setCenter(results[0].geometry.location);
              const marker = new window.google.maps.Marker({
                map: this.map,
                position: results[0].geometry.location
              });
            } else {
              console.error('Geocode was not successful for the following reason: ' + status);
            }
          });
        });
        res.data.checkPayment.forEach((value, key) => {
          if (propertyId == value.propertyId) {
            this.booked = true;
          }
        });
        const dateParts = this.targetDate.split('-');
        this.targetTime = Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2], 23, 59, 59);

        this.updateCountdown();

        this.timer = setInterval(this.updateCountdown, 1000);
      }, err => {
        if (err.response && err.response.data && err.response.data.message === "Unauthenticated.") {
          localStorage.setItem('originalUrl', this.$route.fullPath);
          this.$router.push({ name: 'guest_login' });
        } else {
          console.log(err);
        }
      })
    },

    getRatingAndReviews(propertyId) {
      let sum = 0;
      let count = 0;
      API.getRatingAndReviews(propertyId, res => {
        res.data.forEach((value, key) => {
          if (!isNaN(value.rating)) {
            sum += parseInt(value.rating);
            count++;
          }
        });
        this.reviews = count;
        this.rating = count > 0 ? sum / count : 0;
      }, err => {
        console.log(err)
      })
    },
    setRating(value) {
      this.rating = value;
    },
    changeActive(index) {
      this.activeIndex = index;
    },
    redirectToPayment() {

      let Authorization = localStorage.getItem('isLoggedin');
      if (Authorization) {
        if (!this.dateRange.startDate || !this.dateRange.endDate) {
          alert('Please Select Available Dates.');
        } else {
          let startDate = moment(this.dateRange.startDate);
          let formattedstartDate = startDate.format('YYYY-MM-DD');
          let endDate = moment(this.dateRange.endDate);
          let formattedendDate = endDate.format('YYYY-MM-DD');
          let daysDifference = endDate.diff(startDate, 'days');
          this.$router.push({ name: 'property_payment', params: { guestnumber: this.property.max_guests, prefund: this.prefund, nrefund: this.nrefund, parrefund: this.parrefund, days: daysDifference, totalPrice1: (this.totaldicPrice1 !== 0) ? this.totalPrice1 : ((this.totalPrice1 === 0) ? this.pfprice : this.totalPrice1), pfprice: this.pfprice, currency: this.currency, totalPrice: this.totalPrice, startDate: formattedstartDate, endDate: formattedendDate } });
        }
      } else {
        this.$bvModal.show('modal-4');
        // this.$router.push({ name: 'guest_login' });
      }
    },
    redirectToRating() {
      this.$router.push({ name: 'property_rating' });
    }
  },
  created() {
    this.fetchCurrency();
    if (this.$route.params.id) {
      sessionStorage.setItem('propertyId', this.$route.params.id);
    }

  },
}
</script>
<style scoped>
/*****************globals*************/
.container-fluid.page-body-wrapper.client-dashbboard {
  padding-top: 75px !important;
}

body {
  font-family: 'open sans';
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

.date-picker .vue-daterange-picker {
  width: 100%;
}

.payment-form {
  margin-bottom: 15px;
}

.reportrange-text {
  background: #fff;
  cursor: pointer;
  padding: 10px 10px;
  border: 1px solid #000;
  overflow: hidden;
  height: auto;
  max-width: 220px;
  color: #000;
  width: 100%;
}

.preview {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.product-detail-sec {
  width: 100%;
}

.pricing-detail-box {
  border: 1px solid #dfe0e4;
  padding: 1.5rem;
  margin: .75rem;
  border-radius: 20px;
  position: sticky;
  top: 30px;
}

.product-detail-sec .col-md-4 {
  position: relative;
}

.pricing-detail-box .heading {
  display: flex;
  align-items: flex-end;
  gap: .5rem;
}

.pricing-detail-box .heading h2 {
  margin: 0;
  color: #191e3b;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2rem;
}

.pricing-detail-box .heading span {
  font-size: .875rem;
  line-height: 1.125rem;
  min-inline-size: calc(4px + 1.125rem);
}

.date-pricing {
  display: flex;
  align-items: center;
  margin-top: .75rem;
  padding-top: .75rem;
  border-top: 1px solid #dfe0e4;
  padding-bottom: .75rem;
}

.date-pricing svg {
  width: 18px;
  height: 18px;
  margin-right: .5rem;
}

.date-pricing span {
  font-size: .875rem;
  line-height: 1.125rem;
  min-inline-size: calc(4px + 1.125rem);
}

.rating-counter {
  padding-left: 4px;
  padding-right: 4px;
  background-color: #e2ebfa;
  border-radius: 5px;
  font-weight: 600;
  color: #000;
}

.form-section {
  padding: 0.7rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-section .form-field {
  width: 48%;
}

.form-section .form-field:last-child {
  width: 100%;
}

.form-section .form-field .form-control {
  height: 48px;
  border: 1px solid #000;
  border-radius: 8px;
  color: #000;
}

.form-control {
  display: block;
  width: 100%;
  height: 46px;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5rem;
  color: #292929;
  background-color: #fff;
  background-image: none;
  border: 1px solid #676A7D;
  border-radius: 30px;
  box-shadow: none;
  outline: none;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

textarea.form-control {
  height: 110px;
  padding: 15px;
}

.share-buttons {
  display: flex;
  justify-content: center;
}

.share-buttons .share-icons {
  width: 30px;
  height: 30px;
  border: 1px solid #676A7D;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 100px;
  color: #000000;
  background: #fff;
  cursor: pointer;
}

.share-buttons .share-icons svg {
  width: 15px;
  height: 15px;
}

.share-buttons .share-icons:hover {
  background: #70d3ff;
  color: #fff;
  border-color: #70d3ff;
}

.share-buttons .share-icons:hover svg {
  fill: #fff;
}

.product-detail-sec h2 {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2rem;
}

.product-detail-sec ul {
  padding: 0;
  margin: 0;
}

.product-detail-sec p,
.product-detail-sec span {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  min-inline-size: calc(4px + 1.125rem);
}

.product-detail-sec h3 {
  font-size: 1.45rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.pricing-anchor {
  padding: .75rem;
}

.pricing-anchor a {
  font-size: .875rem;
  line-height: 1.125rem;
  text-decoration: underline;
}

.form-btn .availbility-btn {
  font-size: 1rem;
  line-height: 1.25rem;
  min-block-size: 2.25rem;
  padding: .25rem 1rem;
  background: #70d3ff;
  color: #fff;
  border: 1px solid #70d3ff;
  width: 100%;
  gap: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2500rem;
}

.form-btn .availbility-btn:hover {
  color: #70d3ff;
  background: #fff;
}

.location-list ul li {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.location-list ul li span:nth-child(2) {
  margin-right: auto;
}

.location-list ul li span i {
  font-size: 1.2rem;
}

.popular-amenities,
.explore-area,
.spaces-detail,
.about-property {
  margin-bottom: 2.5rem;
}

.map-sec {
  border: 1px solid #dfe0e4;
  border-radius: 15px;
}

.rooms-and-bed h2 {
  margin-bottom: 30px;
}

.rooms-and-bed .room-bed-detail {
  border-bottom: 1px solid #dfe0e4;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
}

.rooms-and-bed .room-bed-detail h3 {
  margin-bottom: 15px;
}

.rooms-and-bed .room-bed-detail ul {
  display: flex;
  justify-content: space-between;
}

.rooms-and-bed .room-bed-detail ul li {
  width: 50%;
}

.rooms-and-bed .room-bed-detail ul li h3 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 0;
}

.spaces-detail h2,
.about-property h2 {
  margin-bottom: 30px;
}

.space-anchor a {
  font-size: .875rem;
  gap: .25rem;
  line-height: 1.125rem;
  display: flex;
}

.about-property p {
  font-size: .875rem;
  line-height: 1.125rem;
  margin: 0 0 3px;
}

.about-property .about-details {
  margin-bottom: 20px;
}

.about-property .about-details h3 {
  margin-bottom: 10px;
}

.about-property .about-details .image {
  margin-bottom: 12px;
}

.about-property .about-details .image img {
  max-width: 40px;
}

.two-column .accordion button {
  background: #fff !important;
  border: 0 !important;
  box-shadow: none !important;
  outline: none !important;
  color: #191e3b !important;
}

@media screen and (max-width: 996px) {
  .preview {
    margin-bottom: 20px;
  }
}

.preview-pic {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.preview-thumbnail.nav-tabs {
  border: none;
  margin-top: 15px;
}

.preview-thumbnail.nav-tabs li {
  width: 18%;
  margin-right: 2.5%;
}

.preview-thumbnail.nav-tabs li img {
  max-width: 100%;
  display: block;
}

.preview-thumbnail.nav-tabs li a {
  padding: 0;
  margin: 0;
}

.preview-thumbnail.nav-tabs li:last-of-type {
  margin-right: 0;
}

.tab-content {
  overflow: hidden;
}

.tab-content img {
  width: 100%;
  -webkit-animation-name: opacity;
  animation-name: opacity;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
}

.send-message button.btn.btn-primary {
  font-size: 16px !important;
  line-height: 1.25rem;
  min-block-size: 2.25rem;
  padding: .25rem 1rem;
  background: #70d3ff !important;
  color: #fff;
  border: 1px solid #70d3ff;
  width: 100%;
  gap: .5rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 30px !important;
}

.card {
  line-height: 1.5em;
  border: 0;
}

.tabs-list {
  width: 100%;
}

.tabs-list ul {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  min-block-size: 2.75rem;
  position: relative;
  background: #fff;
  block-size: 2.75rem;
  border-bottom: 1px solid #dfe0e4;
  width: 100%;
}

.tabs-list ul li {
  cursor: pointer;
  text-align: center;
}

.tabs-list ul li a {
  padding: 0 1rem;
  color: #191e3b;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  block-size: 100%;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}

.tabs-list ul li a.active {
  color: #70d3ff;
  border-bottom: 2px solid #70d3ff;
  box-shadow: none;
}

.tabs-list ul li a:hover {
  border-bottom: 2px solid #191e3b;
}

.details.details-section {
  padding: 1.5rem;
}

.preview .row.thumbnail-holder.m-0 {
  gap: 2px;
  flex-wrap: nowrap;
}

.preview .thumbnail-img {
  padding-bottom: 2px;
}

.preview .thumbnail-img img {
  height: 100%;
}

.preview .col-lg-3.p-0 .thumbnail-img {
  height: 50%;
}

.popular-amenities h3,
.content-list h3 {
  font-size: 1.45rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: #191e3b;
  margin-bottom: .75rem;
}

.content-list ul {
  padding: 0;
  margin: 0;
}

.content-list ul li {
  position: relative;
  padding-left: 15px;
}

.content-list ul li:after {
  width: 5px;
  height: 5px;
  background: #000;
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  display: block;
  border-radius: 100px;
  transform: translateY(-50%);
}

.popular-amenities ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.popular-amenities ul li {
  width: 50%;
}

.air-condition {
  font-size: .875rem;
  line-height: 1.125rem;
  min-inline-size: calc(4px + 1.125rem);
  display: flex;
  align-items: center;
  padding-bottom: .75rem;
}

.air-condition span {
  padding-right: .5rem;
}

.air-condition span i {
  font-size: 20px;
}

button.see-all-btn i {
  font-size: 18px;
  line-height: 17px;
}

button.see-all-btn {
  color: #70d3ff !important;
  background: #fff;
  border: 0;
  padding: 0;
  font-size: .875rem;
  gap: .25rem;
  line-height: 1.125rem;
  display: flex;
  align-items: center;
  padding-left: 0 !important;
  background: #fff !important;
  box-shadow: none !important;
}

@media screen and (min-width: 997px) {
  .wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.colors {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.product-title,
.price,
.sizes,
.colors {
  text-transform: UPPERCASE;
  font-weight: bold;
}

.checked,
.price span {
  color: #ff9f1a;
}

.product-title,
.rating,
.product-description,
.price,
.vote,
.sizes {
  margin-bottom: 15px;
}

.product-title {
  margin-top: 0;
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 2.5rem;
}

.size {
  margin-right: 10px;
}

.size:first-of-type {
  margin-left: 40px;
}

.color {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  height: 2em;
  width: 2em;
  border-radius: 2px;
}

.color:first-of-type {
  margin-left: 20px;
}

.add-to-cart,
.like {
  background: #ff9f1a;
  padding: 1.2em 1.5em;
  border: none;
  text-transform: UPPERCASE;
  font-weight: bold;
  color: #fff;
  -webkit-transition: background .3s ease;
  transition: background .3s ease;
}

.add-to-cart:hover,
.like:hover {
  background: #b36800;
  color: #fff;
}

.not-available {
  text-align: center;
  line-height: 2em;
}

.not-available:before {
  font-family: fontawesome;
  content: "\f00d";
  color: #fff;
}

.orange {
  background: #ff9f1a;
}

.green {
  background: #85ad00;
}

.blue {
  background: #0076ad;
}

.tooltip-inner {
  padding: 1.3em;
}

@-webkit-keyframes opacity {
  0% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/*# sourceMappingURL=style.css.map */
</style>
<style scoped>
.progressbar {
  counter-reset: step;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.progressbar li {
  float: left;
  width: 24.28%;
  position: relative;
  text-align: center;
  color: #191e3b;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.125rem;
}

.product-detail-sec .paragrapgh p,
.product-detail-sec .paragrapgh span {
  color: #191e3b;
  font-size: 1.075rem;
  font-weight: 400;
  line-height: 1.125rem;
}

.progressbar li i {
  display: block;
  margin: 4px auto 5px auto;
  color: #191e3b;
  /* Default icon color */
}

.progressbar li:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #191e3b;
  top: 10px;
  left: -50%;
  z-index: 0;
}

.progressbar li:last-child:after {
  background: #818494;
}

.progressbar li:last-child .fa-times:before {
  border: 1px solid #818494;
}

.progressbar li.active i {
  color: #191e3b;
}

.progressbar li.active:after {
  background: #3aac5d;
}

.progressbar li:first-child:after {
  content: none;
}

.cancelbar {
  margin-bottom: 20px;
  border-radius: 21px;
  background: #fff;
  padding-left: 0;
  padding-top: 10px;
}

.fa-check:before {
  border: 1px solid black;
  padding: 5px;
  border-radius: 100px;
  color: green;
  background: #f7f7f8;
  position: relative;
  z-index: 9;
}

.fa-times:before {
  border: 2px solid black;
  border-radius: 100px;
  color: transparent;
  background: #fff;
  position: relative;
  z-index: 9;
  width: 12px;
  height: 12px;
  display: inline-block;
}

.fa-minus:before {
  border: 1px solid black;
  padding: 5px 7px;
  border-radius: 100px;
  color: grey;
  background: #f7f7f8;
  position: relative;
  z-index: 9;

}

.fa-circle:before {
  padding: 0;
  border-radius: 100px;
  background-color: #f7f7f8;
  font-size: 14px;
  position: relative;
  z-index: 9;
}

.accordian-guests {
  position: relative;
}

.form-group label {
  font-size: 18px;
}

.popup-guest {
  border: 1px solid rgb(231 231 232);
  border-radius: .5rem;
  background: white;
  box-shadow: 0 2px .75rem rgba(12, 14, 28, .08);
  display: block;
  max-width: 100%;
  margin-left: auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  position: absolute;
}

.guest-content {
  display: flex;
  align-items: center;
  border-right: 0 !important;
  justify-content: space-between;
  width: 100%;
}

.guest-content .guest-col h4 {
  margin: 0;
  font-weight: 400;
  font-size: 15px;
}

.guest-content .guest-col .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-append,
.input-group-prepend {
  background: #ffffff;
  color: #c9c8c8;
  width: auto;
  border: none;
}

.input-group-prepend {
  margin-right: -1px;
}

.guest-content .form-control {
  padding: 0;
  height: 30px;
  border: 0;
  text-align: center;
  width: 60px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.center-tabs-layout .tabs-content p,
.guest-content .form-control {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

.guest-content .btn.btn-info {
  padding: 0;
  width: 30px;
  height: 30px;
  font-size: 24px;
  background: transparent;
  border: 1px solid rgb(192 194 196);
  color: #000;
  border-radius: 100px;
}

.guest-content .form-control::-webkit-outer-spin-button,
.guest-content .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
<style scoped>
.date-picker-container {
  display: flex;
  gap: 10px;
}

.date-picker-container .datepicker {
  flex: 1;
}

.strikethrough {
  text-decoration: line-through;
}

.timer {
  position: absolute;
  top: 42px;
  right: 10px;
  margin: 0 30px;
}

.long_stay_dis {
  border: 1px solid #dfe0e4;
  padding: 1.5rem;
  margin: .75rem;
  border-radius: 20px;
}

.long_stay_dis h4 {
  margin: 0 0 15px;
  color: #191e3b;
  font-size: 1.55rem;
  font-weight: 500;
  line-height: 2rem;
  border-bottom: 1px solid #dfe0e4;
  padding-bottom: 10px;
}

.long_stay_dis .long_dis {
  margin-bottom: 15px;
}
</style>